<template>
    <div class="home">
        <!-- 头部 -->
        <myHead></myHead>


        <div class="contentType">
            <div class="l">
                <div class="dh">订单号：{{ oid }}</div>
                <div class="img">
                    <img src="@/assets/steps/sb.png" v-if="orderIdInfo.ostatus == 'JYGB'" alt="">
                    <img src="@/assets/steps/cg.png" v-else alt="">
                </div>
                <div :class="[orderIdInfo.ostatus == 'JYGB' ? 'bt2' : 'bt']">{{ activationType }}</div>
            </div>
            <div class="r">
                <div class="steps">
                    <div class="son_box" v-for="(i, index) in stepArr" :key="index">
                        <div class="son">
                            <div class="img">
                                <img :src="i.src" alt="">
                            </div>
                            <span>{{ i.name }}</span>
                        </div>
                        <div class="ht" v-if="index !== 4">
                            <img src="@/assets/steps/ht.png" alt="">
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="ddInfo" v-if="orderIdInfo.oid">
            <div class="son_box">
                <div class="dh">
                    <span>
                        <div>订单编号：{{ oid }}</div>
                        <div>支付时间：{{ orderIdInfo.payTime || '--' }}</div>
                    </span>
                </div>
                <div class="cont" v-for="(i, index) in orderIdInfo.listOrderItem" :key="index">
                    <div class="img_jiag">
                        <div class="img">
                            <img :src="i.img" alt="">
                        </div>
                        <div class="tetx">
                            <div class="name">{{ i.productName }}</div>
                            <div class="jiag">x{{ i.pnum }}</div>
                        </div>
                    </div>
                    <div class="typee2">
                        <div class="ty">单价：¥{{ i.price }}</div>
                        <div v-if="i.type == '2'"
                            style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-top: 5px;">
                            定金</div>
                    </div>
                    <div class="typee2">
                        <div class="ty">优惠：¥{{ ((i.price * i.pnum) - i.paymentMoney).toFixed(2) < 0 ? 0 : ((i.price *
                            i.pnum) - i.paymentMoney).toFixed(2) }}</div>
                                <!-- <div class="ty">运费：¥{{ i.freightPrice }}</div> -->
                        </div>
                        <div class="typee">
                            <div class="ty2">商品总价¥{{ i.price * i.pnum }}</div>
                            <div class="num">实付款¥{{ i.paymentMoney }}</div>
                        </div>

                        <div class="btn_box">

                            <!-- <div class="btn" @click="applyForAfterSales">申请售后321</div> -->
                            <div class="btn2" @click="addCart(i)"
                                v-if="orderIdInfo.ostatus == 'JYCG' || orderIdInfo.ostatus == 'JYGB'">
                                加入购物车</div>
                            <div class="btn2"
                                v-if="orderIdInfo.ostatus === 'DFH' || ((orderIdInfo.ostatus === 'DZF1' || orderIdInfo.ostatus === 'DZF2') && orderIdInfo.payType == 'HK')"
                                @click.stop="selectAssClick(orderIdInfo)">修改地址</div>
                            <div class="btn" @click="applyForAfterSales(i, orderIdInfo)"
                                v-if="orderIdInfo.ostatus !== 'JYGB' && orderIdInfo.ostatus !== 'DZF' && type === 'user' && (i.status == '0' || i.status == '2' || !i.status) && (i.statusDesc == '2' || !i.statusDesc) && orderIdInfo.paymentMoney && i.isRefund == '0'">
                                申请售后</div>
                            <div class="btn" v-if="i.status && i.status != '0'" @click="detailClick(i)">
                                查看详情</div>




                            <div class="btn2" v-if="orderIdInfo.ostatus === 'DSH'" @click="affirmOrder(orderIdInfo.oid, 'DPJ')">
                                确认收货</div>
                            <div class="btn2" v-if="orderIdInfo.ostatus === 'DZF'"
                                @click.stop="cancellationOrderClick(orderIdInfo)">
                                取消订单</div>




                            <div class="btn" v-if="i.status == '1' && i.listOrderRefunds">
                                退款处理中</div>
                            <div class="btn2" v-if="i.status == '2'">
                                申请驳回</div>
                            <div class="btn2" v-if="i.status == '3'">
                                退款成功</div>
                            <template v-if="!orderIdInfo.ostatus.includes('DZF') && !orderIdInfo.ostatus.includes('JYGB')">
                                <div class="btn2" @click.stop="Invoicing(orderIdInfo)" v-if="!orderIdInfo.invoiceId">
                                    申请开票</div>

                                <div class="btn2" @click.stop="InvoicingImg(orderIdInfo)"
                                    v-if="orderIdInfo.invoiceId && orderIdInfo.userInvoiceProof">
                                    查看发票</div>

                                <div class="btn3" v-if="orderIdInfo.invoiceId && !orderIdInfo.userInvoiceProof">
                                    开票中</div>
                            </template>

                        </div>
                    </div>
                </div>
                <div class="totalPrice">
                    <span> 优惠：¥{{ (orderIdInfo.listOrderItem.reduce((total, item) => total + (item.price * item.pnum), 0) -
                        orderIdInfo.listOrderItem.reduce((total, item) => total + item.paymentMoney, 0)).toFixed(2)
                    }}</span> <span>运费：¥{{
    orderIdInfo.listOrderItem[0].freightPrice }}</span> <span>商品总价：¥{{
        orderIdInfo.listOrderItem.reduce((total, item) => total + (item.price * item.pnum), 0) }}</span>
                </div>
                <div class="realPay">
                    <div> 实付款：<span>¥{{ orderIdInfo.paymentMoney }}</span></div>
                </div>
                <div class="btnnBox" @click="centerDialogVisible2 = true"
                    v-if="(orderIdInfo.ostatus === 'DZF' && orderIdInfo.paymentMoney && orderIdInfo.otype == 'DISCUSS') || (orderIdInfo.ostatus === 'DZF' && orderIdInfo.otype != 'DISCUSS' && !orderIdInfo.platformAuditRemark)">
                    付款
                </div>
                <div class="btnnBox" style="width: 140px;margin-right: 20px;" @click.stop="getRepayment()"
                    v-if="(orderIdInfo.ostatus === 'DZF' && orderIdInfo.paymentMoney && orderIdInfo.otype == 'DISCUSS') || (orderIdInfo.ostatus === 'DZF' && orderIdInfo.otype != 'DISCUSS' && orderIdInfo.platformAuditRemark)">
                    查看并重新付款</div>
                <div style="clear: both;"></div>

            </div>

            <div class="theOrderInformation">
                <div class="l">
                    <div class="tit">订单信息</div>
                    <div class="son">快递类型：<span>{{ wuliuTxt || '--' }}</span></div>
                    <div class="son">订单编号：<span>{{ oid }}</span></div>
                    <div class="son">支付方式：<span>{{ payType == 'WX' ? '微信支付' : payType == 'ZFB' ? '支付宝支付' : ' 线下汇款' }}</span>
                    </div>
                    <div class="son">创建时间：<span>{{ orderIdInfo.createTime || '--' }}</span></div>
                    <div class="son">付款时间：<span>{{ orderIdInfo.payTime || '--' }}</span></div>
                    <div class="son">用户备注：<span style="width: calc(100% - 100px);">{{ orderIdInfo.remark || '--' }}</span>
                    </div>
                </div>
                <div class="r">
                    <div class="tit">收货信息</div>
                    <div class="son">实收货人：<span>{{ orderIdInfo.uname }}</span></div>
                    <div class="son"> 联系方式：<span>{{ orderIdInfo.utelephone }} </span></div>
                    <div class="son"> 收货地址：<span>{{ orderIdInfo.province }}/{{ orderIdInfo.city }}/{{ orderIdInfo.county
                    }}/{{ orderIdInfo.address }} </span></div>
                </div>
            </div>
            <!-- 查看发票对话框 -->
            <el-dialog :visible.sync="userInvoiceProofShow">
                <img width="100%" :src="userInvoiceProof" alt="">
            </el-dialog>

            <!-- 申请开票对话框 -->
            <InvoicingKP :outerVisible="outerVisible" @outerVisibleShowClick="outerVisibleShowClick"
                @getOrderList="getOrderIdInfo" :paymentObj="orderIdInfo" />

            <offlineZF :centerDialogVisible2="centerDialogVisible2" :paymentObj="orderIdInfo"
                @centerDialogVisible2Click="centerDialogVisible2Click"></offlineZF>
                
            <offlineHKJuJ :dialogVisible="dialogVisibleJuJ" @dialogVisibleShowClick="dialogVisibleShowClick"
                @repaymentClick="repaymentClick" :platformAuditRemark="orderIdInfo.platformAuditRemark" />

            <!-- 底部 -->
            <bottomContent></bottomContent>
        </div>
</template>
<script>
import offlineHKJuJ from "@/pages/myPage/offlineHKJuJ.vue";
import offlineZF from "@/pages/myPage/offlineZF.vue";
import InvoicingKP from "@/pages/myPage/InvoicingKP.vue";
import { getOrderIdInfo, getDeliveryType, getOrderSelectAddreee, postAffirmOrder } from '@/utils/api/myApi/index'
import { getAddCart } from '@/utils/api/homeApi/index'
import myHead from "@/components/myHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'orderDetails',
    components: {
        myHead,
        bottomContent,
        offlineZF,
        offlineHKJuJ,
        InvoicingKP
    },
    data() {
        return {
            // 申请开票对话框
            outerVisible: false,
            // 查看开票对话框
            userInvoiceProofShow: false,
            // 发票图片
            userInvoiceProof: '',

            dialogVisibleJuJ: false,
            centerDialogVisible2: false,
            stepArr: [
                {
                    name: '创建订单',
                    src: require('@/assets/steps/cjdd.png'),
                    srcA: require('@/assets/steps/cjddA.png')
                },
                {
                    name: '成功支付',
                    src: require('@/assets/steps/zfcg.png'),
                    srcA: require('@/assets/steps/zfcgA.png')
                },
                {
                    name: '商家发货',
                    src: require('@/assets/steps/sjfh.png'),
                    srcA: require('@/assets/steps/sjfhA.png')
                },
                {
                    name: '确认收货',
                    src: require('@/assets/steps/qrsh.png'),
                    srcA: require('@/assets/steps/qrshA.png')
                },
                {
                    name: '订单完成',
                    src: require('@/assets/steps/ddwc.png'),
                    srcA: require('@/assets/steps/ddwcA.png')
                },
            ],
            // 激活第几步
            activationIndex: 0,
            // 激活状态文字
            activationType: '',
            // 订单号
            oid: '',
            // 支付方式
            payType: '',
            // 订单详情
            orderIdInfo: {},
            // 物流类型
            wuliuTxt: '',
            type: 'user'
        }
    },
    mounted() {
        this.payType = this.$route.query.payType
        this.oid = this.$route.query.oid
        // 拿订单信息
        this.getOrderIdInfo()

    },
    methods: {
        // 确认收货
        async affirmOrder(oId, status) {
            const res = await postAffirmOrder({
                oId: oId,
                oStatus: status
            })
            if(res.statusCode == 8201) {
                this.$message.success('操作成功')
                this.getOrderIdInfo()
            }
        },
        // 取消订单
        async cancellationOrderClick(i) {
            let data = {
                oId: i.oid,
                oStatus: 'JYGB',
                // payType: 
            }
            const res = await getOrderSelectAddreee(data)
            if (res.data) {
                this.$message.success('已取消该订单')
                this.getOrderIdInfo()
            } else {
                this.$message.success('操作失败')
            }
        },
        // 查看发票
        InvoicingImg(i) {
            this.userInvoiceProof = i.userInvoiceProof
            this.userInvoiceProofShow = true
        },
        // 申请开票
        Invoicing(i) {
            this.paymentObj = i
            this.outerVisible = true
        },
        // 关闭开票对话框
        outerVisibleShowClick() {
            this.outerVisible = false
        },
        // 修改地址
        selectAssClick(i) {
            console.log(i.oid)
            this.$router.push(`/myIndex/myAddress?actMyNum=4&type=select&oid=${i.oid}`)
        },
        dialogVisibleShowClick() {
            this.dialogVisibleJuJ = false
        },
        repaymentClick() {
            this.dialogVisibleJuJ = false
            this.centerDialogVisible2 = true
        },
        getRepayment() {
            this.dialogVisibleJuJ = true
        },
        // -
        centerDialogVisible2Click() {
            this.centerDialogVisible2 = false
        },
        // 查看详情
        detailClick(i) {
            this.$router.push(`/myIndex/afterTheDetails?actMyNum=1&returnsId=${i.listOrderRefunds[0].returnsId}`)
            console.log(i)
        },
        // 订单信息
        async getOrderIdInfo() {
            const res = await getOrderIdInfo(this.oid)
            if (res.statusCode == 8201) {
                this.orderIdInfo = res.data
                if (res.data.expressType) {
                    this.getDeliveryTypeAll(res.data.expressType)
                }
                let i = res.data
                if ((i.ostatus == 'DZF' || i.ostatus == 'DZF1') && i.otype == 'DISCUSS' && i.paymentMoney == '0') {
                    this.activationType = '等待商家报价'
                }
                if (((i.ostatus == 'DZF' || i.ostatus == 'DZF1') && i.otype != 'DISCUSS') || ((i.ostatus == 'DZF' || i.ostatus == 'DZF1') && i.otype == 'DISCUSS' && i.paymentMoney != '0')) {
                    this.activationType = '等待买家付款'
                }
                if ((i.ostatus == 'DZF1' || i.ostatus == 'DZF2') && i.payType == 'HK') {
                    this.activationType = '线下付款审核中'
                }
                if (i.ostatus == 'DZF' && i.payType == 'HK' && i.platformAuditRemark) {
                    this.activationType = '线下汇款未通过'
                }
                if (i.ostatus == 'DFH') {
                    this.activationType = '等待卖家发货'
                    this.activationIndex = 1
                }
                if (i.ostatus == 'DSH') {
                    this.activationType = '卖家已发货'
                    this.activationIndex = 2
                }
                if (i.ostatus == 'DPJ') {
                    this.activationType = '交易成功'
                    this.activationIndex = 3
                }
                if (i.ostatus == 'THSQ') {
                    this.activationType = '售后审核中'
                }
                if (i.ostatus == 'JYGB') {
                    this.activationType = '交易关闭'
                }
                if (i.ostatus == 'JYCG') {
                    this.activationType = '订单完成'
                    this.activationIndex = 4
                }
                this.stepArrActivationIndex()
            }
        },
        // 获取快递类型
        async getDeliveryTypeAll(data) {
            const res = await getDeliveryType()
            if (res.statusCode == 8201) {
                res.data.ShipCodeType.map(d => {
                    if (d.code == data) {
                        this.wuliuTxt = d.msg
                        return
                    }
                })
            }
        },
        // 状态步骤-图标激活
        stepArrActivationIndex() {
            this.stepArr.map((t, index) => {
                if (index <= this.activationIndex) {
                    this.stepArr[index].src = this.stepArr[index].srcA
                }
            })
        },
        // 申请售后
        applyForAfterSales(i, orderIdInfo) {
            console.log(i)
            localStorage.setItem('mallStoreObjSH', JSON.stringify(i))
            localStorage.setItem('mallStoreObjSH2', JSON.stringify(orderIdInfo))
            this.$router.push('/myIndex/applyForAfterSales?actMyNum=0')
        },
        // 假如购物车
        async addCart(i) {
            let data = {
                skuId: i.skuId,
                num: i.pnum
            }
            const res = await getAddCart(data)
            if (res.data) {
                this.$message.success('已加入购物车')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    >.contentType {
        width: 1200px;
        height: 306px;
        margin: 20px auto;
        background-color: #fff;
        display: flex;

        >.l {
            width: 320px;
            height: 100%;
            border-right: 1px solid #999999;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >.dh {
                color: #999999;
                font-size: 14px;
            }

            >.img {
                width: 34px;
                height: 34px;
                margin-top: 45px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.bt {
                width: 120px;
                height: 30px;
                border-radius: 4px;
                border: 1px solid #FF4242;
                font-size: 14px;
                color: #FF4242;
                margin-top: 35px;
                text-align: center;
                line-height: 30px;
            }

            >.bt2 {
                width: 120px;
                height: 30px;
                border-radius: 4px;
                border: 1px solid #666;
                font-size: 14px;
                color: #666;
                margin-top: 35px;
                text-align: center;
                line-height: 30px;
            }
        }

        >.r {
            width: 880px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >.steps {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;

                >.son_box {
                    display: flex;

                    >.son {
                        width: 56px;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        >.img {
                            width: 33px;
                            height: 33px;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        >span {
                            font-size: 14px;
                            color: #333;
                        }
                    }

                    >.ht {
                        width: 120px;
                        height: 8px;
                        margin: 0 5px;
                        margin-top: 10px;

                        >img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }



            }
        }
    }

    >.ddInfo {
        width: 1200px;
        // height: 260px;
        margin: 20px auto;
        background-color: #fff;
        padding: 20px 16px;
        box-sizing: border-box;

        .son_box {
            width: 100%;
            height: auto;
            border: 1px solid #E0E0E0;
            margin-bottom: 20px;


            >.dh {
                width: 100%;
                padding: 11px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                color: #333;
                background-color: #EEEEEE;

                >span {
                    display: flex;

                    >div {
                        margin-right: 40px;
                    }
                }

                >i {
                    cursor: pointer;
                }

            }

            >.cont {
                width: 100%;
                min-height: 88px;
                padding-top: 10px;
                display: flex;
                align-items: center;
                border-top: 1px solid #f0f0f0;

                >.img_jiag {
                    width: 440px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    box-sizing: border-box;
                    border-right: 1px solid #E0E0E0;

                    >.img {
                        width: 64px;
                        height: 64px;

                        >img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    >.tetx {
                        margin-left: 10px;

                        >.name {
                            font-size: 14px;
                            color: #333;
                        }

                        >.jiag {
                            font-size: 10px;
                            color: #999;
                            margin-top: 10px;
                        }
                    }
                }

                >.typee {
                    width: 230px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #E0E0E0;

                    >.ty2 {
                        font-size: 14px;
                        color: #999;
                    }

                    >.num {
                        font-size: 14px;
                        color: #FF4242;
                        margin-top: 5px;
                    }
                }

                >.typee2 {
                    width: 130px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // border-left: 1px solid #E0E0E0;
                    border-right: 1px solid #E0E0E0;

                    >.ty {
                        font-size: 14px;
                        color: #999;
                    }

                    >.nam {
                        font-size: 12px;
                        color: #999999;
                        margin-top: 5px;
                    }
                }

                >.btn_box {
                    width: 230px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    >div {
                        width: 80px;
                        height: 30px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        font-size: 12px;
                        text-align: center;
                        line-height: 30px;
                        margin-bottom: 5px;
                        cursor: pointer;
                    }

                    >.btn {
                        background-color: #FF4242;
                        color: #fff;
                    }

                    >.btn2 {
                        color: #FF4242;
                        border: 1px solid #FF4242;
                    }

                    >.btn3 {
                        color: #666;
                    }
                }
            }
        }

        >.totalPrice {
            width: 100%;
            display: flex;
            font-size: 14px;
            color: #999;
            justify-content: flex-end;

            >span {
                margin-left: 15px;
            }
        }

        >.realPay {
            width: 100%;
            display: flex;
            font-size: 14px;
            color: #FF4242;
            justify-content: flex-end;
            margin-top: 15px;

            span {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    >.theOrderInformation {
        width: 1200px;
        // height: 264px;
        background-color: #fff;
        padding: 30px;
        box-sizing: border-box;
        margin: 20px auto;
        display: flex;

        >.l,
        .r {
            width: 520px;
            height: 100%;
            border-right: 1px solid rgba(153, 153, 153, 0.50);

            >.tit {
                font-size: 16px;
                color: #333;
                margin-bottom: 20px;
            }

            >.son {
                display: flex;
                // align-items: center;
                font-size: 14px;
                color: #333;
                margin-top: 5px;

                >span {
                    margin-left: 20px;
                    margin-top: 2px;
                }
            }
        }

        >.r {
            width: 680px;
            border: 0;
            padding-left: 99px;
            box-sizing: border-box;
        }
    }
}

.btnnBox {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: #FF4242;
    color: #fff;
    float: right;
    margin-top: 10px;
}
</style>